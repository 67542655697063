import React from "react";
import Navigation from "../../components/Navigation/Navigation.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import styled from "styled-components";
import ContentBackground from "../../components/ContentBackground/ContentBackground.jsx";
import ContentContainer from "../../components/Content/ContentContainer.jsx";

const GirlsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const Girls = ({ pageData: { girls } }) => {
  return (
    <>
      {girls && (
        <>
          <Navigation />
          <GirlsContainer>
            <ContentBackground content={girls}></ContentBackground>
            <ContentContainer content={girls}></ContentContainer>
          </GirlsContainer>
          <Footer></Footer>
        </>
      )}
    </>
  );
};

export default Girls;
