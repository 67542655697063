import "./Welcome.scss";
import styled from "styled-components";
import { handleRoute } from "../../shared/app-service";
import SplitText from "../../components/SplitText/SplitText";
import { isMobile } from "react-device-detect";
import Footer from "../../components/Footer/Footer";

const WelcomeContainer = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const RegistrationLink = styled.div`
  background-color: ${(props) => props.color};
  padding: 0.2em;
  border-radius: 4px;

  @media screen and (max-width: 1200px) {
    padding: 1em;
  }

  @media screen and (max-width: 600px) {
    padding: 1em;
  }
`;

const Welcome = ({
  pageData,
  mouseOverImg,
  hoveredOver,
  history,
  handleRoute,
  appID,
}) => {
  return (
    <>
      <WelcomeContainer
        img={pageData.welcome?.bkgImg}
        className={
          hoveredOver.type === "boys" ? "welcome_blue" : "welcome_black"
        }
      >
        <div className="welcome_overlay"></div>
        <div className="welcome_ice-logo-container">
          <img alt="" src={pageData.welcome?.iceImg} />
        </div>
        <div className="welcome_content">
          <div className="welcome_content_title">Welcome To Ice Athletics</div>
          <div className="welcome_content_subtitle">
            "The coolest youth league in Southeastern Michigan"
          </div>
          <div className="welcome_content_subtitle2">
            Choose Division Below
            <div id="arrowAnim">
              <div className="arrowSliding">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay1">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay2">
                <div className="arrow"></div>
              </div>
              <div className="arrowSliding delay3">
                <div className="arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="welcome_summer">{pageData.welcome?.headline}</div>

        <div className="welcome_registration">
          <div className="welcome_registration_group">
            {pageData.welcome?.camps?.top &&
              pageData.welcome?.camps?.top.map((camp) => (
                <RegistrationLink
                  color={camp.color}
                  onClick={() => handleRoute(camp.link, "external", history)}
                  className="Registration-G"
                >
                  {camp.name}
                </RegistrationLink>
              ))}
          </div>

          <div className="welcome_registration_group">
            {pageData.welcome?.camps?.bottom &&
              pageData.welcome?.camps?.bottom.map((camp) => (
                <RegistrationLink
                  color={camp.color}
                  onClick={() => handleRoute(camp.link, "external", history)}
                  className="Registration-G"
                >
                  {camp.name}
                </RegistrationLink>
              ))}
          </div>
        </div>
        <div className="welcome_flyer">
          {pageData?.welcome?.flyerImg &&
            pageData?.welcome?.flyerImg.map((flyer, idx) => (
              <div key={idx} className="welcome_flyer_img">
                <img src={flyer} alt="" />
              </div>
            ))}
        </div>
        <div className="welcome_summer">
          Choose Division
          {/* {pageData.welcome?.headline} */}
        </div>
        <div className="welcome_wrapper">
          {pageData.welcome?.sports &&
            pageData.welcome?.sports.map((sport, idx) => (
              <div key={idx} className="welcome_img-container">
                {isMobile ? (
                  <h1 className="welcome_girl-mobile-text">
                    <SplitText copy={sport.type.toUpperCase()} role="heading" />
                  </h1>
                ) : (
                  <>
                    {hoveredOver.id === idx && (
                      <h1 className="welcome_girl-text">
                        <SplitText
                          copy={sport.type.toUpperCase()}
                          role="heading"
                        />
                      </h1>
                    )}
                  </>
                )}
                <img
                  alt=""
                  onMouseEnter={() => mouseOverImg(idx, sport.type)}
                  onMouseLeave={() => mouseOverImg("", "")}
                  onClick={() =>
                    handleRoute(`${sport.type}-athletics`, "locally", history)
                  }
                  className={
                    hoveredOver.id !== idx ? "welcome_grow" : "welcome_shrink"
                  }
                  src={sport.img}
                />
              </div>
            ))}
        </div>
        <canvas></canvas>
      </WelcomeContainer>
      <Footer />
    </>
  );
};

export default Welcome;
