import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./Content.scss";

const OfferWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: ${(props) => props.mainColor};
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s;
`;

const OfferCard = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  width: ${(props) => (props.stretch ? " 425px" : " 250px")};
  margin: 0 10px 8px;
  padding: 2em;
  border-radius: 3px;
  box-shadow: 5px 15px 85px -10px rgb(0 0 0 / 7%);
  background-color: #fff;
  transition: all 0.3s;
  height: 300px;
  cursor: pointer;
  overflow: scroll;

  &:hover {
    z-index: 1;
    transform: translateY(-1px);
    box-shadow: 0 16px 64px 0 rgba(0, 35, 70, 0.2);
  }

  @media screen and (max-width: 500px) {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    width: ${(props) => (props.stretch ? " 425px" : " 100%")};
    margin: 0 10px 8px;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 5px 15px 85px -10px rgb(0 0 0 / 7%);
    background-color: #fff;
    transition: all 0.3s;
    height: 300px;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      z-index: 1;
      transform: translateY(-1px);
      box-shadow: 0 16px 64px 0 rgba(0, 35, 70, 0.2);
    }
  }
`;

const RegisterContainer = styled.div`
  height: 500px;
  width: 65%;
  margin-bottom: 40px;

  transition: all 0.5s ease-in-out;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  flex-direction: row;
  position: relative;

  background-image: url(${(props) => props.bkgImg});
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: animateImg 0.5s ease-in-out forwards;
`;

const RegisterContainerClicked = styled.div`
  height: 500px;
  width: 100%;
  margin-bottom: 40px;
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  flex-direction: row;
  position: relative;

  background-image: url(${(props) => props.bkgImg});
  background-attachment: fixed;
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  animation: move-img 0.5s ease-in-out forwards;
`;

const OfferContent = styled.div`
  margin-top: 20px;
  font-size: ${(props) => (props.stretch ? "15px" : "0.9em")};
`;

const Modal = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  display: flex;
  z-index: 1;
  bottom: 0%;
  left: 0%;
  flex-direction: column;
  position: absolute;
  animation: move-in 0.5s ease-in-out forwards;

  @media screen and (max-width: 500px) {
    height: 300px;
    width: 500px;
    margin: auto;
    transition: all 0.5s ease-in-out;
    background-color: #fff;
    display: flex;
    z-index: 1;
    bottom: 8%;
    left: 5%;
    align-items: center;
    flex-direction: column;
    position: absolute;
    animation: move-in 0.5s ease-in-out forwards;
  }
`;

const Content = ({
  mainColor,
  practice,
  registrationImg,
  registration,
  flyer,
  offerCards,
  leagueInfo,
  camps
}) => {
  let canStretch = false;
  const [registerImg, setRegisterImg] = useState(registrationImg);
  const [stretch, setStretch] = useState(false);
  const [animateClass, setAnimateClass] = useState(false);

  const handleRoute = (r) => {
    const path = window.location.pathname;
    if (path === "/girls-athletics") {
      window.open(camps.top[0].link, "_blank");
    } else {
      window.open(camps.bottom[0].link, "_blank");
    }
  };
  

  useEffect(() => {}, [registerImg]);

  const changeBackgroundImg = (bkImg) => {
    setRegisterImg(bkImg);
  };

  const stretchCard = () => {
    canStretch = !canStretch;
    setStretch(canStretch);
  };

  return (
    <div id="registration" className="main-content">
      <OfferWrapper mainColor={mainColor}>
        <div className="offer-grade">Divisions</div>
        <div className="offer-container">
          {offerCards.map((card, idx) => (
            <OfferCard
              key={idx}
              onClick={() => stretchCard()}
              stretch={stretch}
            >
              {card.offerIcon ? (
                <div className="icon-container">
                  <i className={card.offerIcon}></i>
                </div>
              ) : (
                <div className="icon-pic-container">
                  <img className="icon-img" src={card.offerImg} alt="" />
                </div>
              )}

              <div className="offer-name">
                <div className="offer-ice-name">{card.offerName}</div>
                <div className="offer-ice-grade">{card.offerGrade}</div>
              </div>
              <OfferContent stretch={stretch}>{card.offerContent}</OfferContent>
            </OfferCard>
          ))}
        </div>
      </OfferWrapper>
      <div className="camp-section">
        <div className="donate-container">
          <div className="camp-icon-container">
            <i className="fa-solid fa-basketball camp-icon"></i>
           {leagueInfo}
          </div>
          <div className="donate-not-found">
            <div aria-disabled={true} onClick={() => handleRoute()} className="camp-btn">
              CLICK HERE!!
            </div>
          </div>
        </div>
        <div className="camp-section-img-container">
          <img src={flyer} alt="" />
        </div>
      </div>
      <div className="offer-grade-btm">Registration & Practice/Games</div>
      {animateClass ? (
        <>
          <RegisterContainerClicked bkgImg={registerImg}>
            <div
              onMouseEnter={() => changeBackgroundImg(registrationImg)}
              className="registration-card"
            ></div>
            <div
              onMouseEnter={() => changeBackgroundImg(practice.practiceImg)}
              className="practice-card"
            ></div>
          </RegisterContainerClicked>
        </>
      ) : (
        <RegisterContainer bkgImg={registerImg}>
          <div
            onMouseEnter={() => changeBackgroundImg(registrationImg)}
            className="registration-card"
          >
            <div className="registration-title">Registration</div>
            <div className="registration-content">
              {registration && registration.top}
            </div>
            <div className="registration-content">
              {registration && registration.middle}
            </div>
            <div className="registration-content">
              {registration && registration.bottom}
            </div>
            {/* <div className="registration-button">Email us</div> */}
          </div>
          <div
            onMouseEnter={() => changeBackgroundImg(practice.practiceImg)}
            className="practice-card"
          >
            <div className="practice-title">Practice/Games</div>
            <div className="practice-content">
              {practice && practice.practiceTime}
            </div>
            <div className="practice-content">
              {practice && practice.gameTime}
            </div>
            <div className="practice-content">
              {practice && practice.noGame}
            </div>
            <div className="practice-content">
              {practice && practice.season}
            </div>
          </div>
        </RegisterContainer>
      )}
    </div>
  );
};

export default Content;
