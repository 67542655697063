// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.built-by-container {
  color: lightgray;
  font-size: 10px;
  margin: 2px;
}

.phone-container {
  color: lightgray;
  font-size: 16px;
  margin: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,WAAA;AACF","sourcesContent":[".built-by-container {\n  color: lightgray;\n  font-size: 10px;\n  margin: 2px;\n}\n\n.phone-container {\n  color: lightgray;\n  font-size: 16px;\n  margin: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
