import React from "react";
import styled from "styled-components";
import Breadcrumb from "../BreadCrumb/BreadCrumb";
import "./ContentBackground.scss";

const StyledContainer = styled.div`
  height: 300px;
  width: 100%;
  background-color: white;
  position: relative;
  background-image: url(${(props) => props.img});
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  background-color: black;
  opacity: ${(props) => (props.setOp ? props.setOp : "0.9")};
  width: 100%;
  height: 500px;
`;

const TitleName = styled.div`
  color: white;
  font-size: 60px;
  font-family: "Georama", sans-serif;
  z-index: 1;

  @media screen and (max-width: 500px) {
    font-size: 33px;
    width: 100%;
    font-family: "Georama", sans-serif;
    z-index: 1;
  }
`;

const ContentBackground = ({ content: { bkgImg, opa, title } }) => {
  return (
    <>
      {bkgImg && (
        <StyledContainer img={bkgImg}>
          <StyledOverlay setOp={opa}></StyledOverlay>
          <TitleName>{title}</TitleName>
          <Breadcrumb crumb={title} />
        </StyledContainer>
      )}
    </>
  );
};

export default ContentBackground;
