import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appID } from "../../shared/app-service";
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
  handleRoute,
} from "@cmj526/made-for-us";

export const useContentHook = () => {
  const [pageData, setPageData] = useState({});
  const history = useNavigate();

  useEffect(() => {
    if (hasCookieAndStorage()) {
      setPageData(decode(appStore.get(appID)));
    } else {
      setSessionData("boys", "/api/boys", setPageData);
    }
  }, []);

  return {
    pageData,
    history,
  };
};
