// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-size: 5em;
  text-align: center;
  font-weight: 600;
  color: white;
}

h1 span span {
  color: #f35b04;
  position: relative;
  opacity: 0;
  animation: move-text 0.1s ease-in-out forwards;
  font-family: "Georama", sans-serif;
  font-size: 130px;
  background-color: rgba(0, 35, 70, 0.8);
}

h1 span span:nth-child(2n) {
  color: white;
}

h1 span span:nth-child(3n) {
  color: white;
}

h1 span span:nth-child(4n) {
  color: white;
}

h1 span span:nth-child(5n) {
  color: white;
}

@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 0;
    transform: translateY(10px) translateX(20px);
  }
  50% {
    bottom: 0.2em;
    transform: translateY(10px) translateX(20px);
  }
  100% {
    bottom: 0;
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SplitText/Split.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,UAAA;EACA,8CAAA;EACA,kCAAA;EACA,gBAAA;EACA,sCAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,YAAA;AAKF;;AAFA;EACE;IACE,cAAA;IACA,UAAA;IACA,4CAAA;EAKF;EAFA;IACE,aAAA;IACA,4CAAA;EAIF;EADA;IACE,SAAA;IACA,UAAA;IACA,sCAAA;EAGF;AACF","sourcesContent":["h1 {\n  font-size: 5em;\n  text-align: center;\n  font-weight: 600;\n  color: white;\n}\n\nh1 span span {\n  color: #f35b04;\n  position: relative;\n  opacity: 0;\n  animation: move-text 0.1s ease-in-out forwards;\n  font-family: \"Georama\", sans-serif;\n  font-size: 130px;\n  background-color: rgba(0, 35, 70, 0.8);\n}\nh1 span span:nth-child(2n) {\n  color: white;\n}\nh1 span span:nth-child(3n) {\n  color: white;\n}\nh1 span span:nth-child(4n) {\n  color: white;\n}\nh1 span span:nth-child(5n) {\n  color: white;\n}\n\n@keyframes move-text {\n  0% {\n    bottom: -0.2em;\n    opacity: 0;\n    transform: translateY(10px) translateX(20px);\n  }\n\n  50% {\n    bottom: 0.2em;\n    transform: translateY(10px) translateX(20px);\n  }\n\n  100% {\n    bottom: 0;\n    opacity: 1;\n    transform: translateY(0) translateX(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
