import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import "./Navigation.scss";

const MobileMenuIconContainer = styled.div`
  font-size: 34px;
  text-align: right;
  color: #232f3e;
  cursor: pointer;
  height: 50px;
  display: none;
  justify-content: center !important;
  align-items: center;

  @media (max-width: 560px) {
    display: flex;
    justify-content: center !important;
  }

  @media (max-width: 414px) {
    display: flex;
    justify-content: center !important;
  }
`;

const MenuIcon = styled.i`
  cursor: pointer;
  color: #fa8320;
`;

const MenuMobileContainer = styled.div`
  display: none;
  flex-direction: row;
  position: absolute;
  z-index: 4;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 0.5;
  transition: all 0.5s ease-in-out !important;
  &:hover {
    transition: all 0.5s ease-in-out !important;
    background-color: #f4f5f7;
    opacity: 1 !important;
  }
  @media (max-width: 560px) {
    display: none;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all 0.5s ease-in-out !important;
    background-color: #f4f5f7;
    opacity: 1 !important;
    &:hover {
      display: flex;
      transition: all 0.5s ease-in-out !important;
      background-color: #f4f5f7;
      opacity: 1 !important;
    }
  }

  @media (max-width: 414px) {
    display: none;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all 0.5s ease-in-out !important;
    background-color: #f4f5f7;
    opacity: 1 !important;
    &:hover {
      display: flex;
      transition: all 0.5s ease-in-out !important;
      background-color: #f4f5f7;
      opacity: 1 !important;
    }
  }
`;

const Navigation = ({ children }) => {
  const [showLinkOptions, setLinkOptions] = useState(false);
  const [showAthleticsLinkOptions, setAthleticsLinkOptions] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const showOptions = (setOp) => {
    setLinkOptions(setOp);
  };

  const showAthleticsOptions = (setOp) => {
    setAthleticsLinkOptions(setOp);
  };

  const showNavMenu = () => {
    const el = document.getElementById("menu-mobile-container");
    if (isShowing) {
      el.style.display = "flex";
      setIsShowing(false);
    } else {
      el.style.display = "none";
      setIsShowing(true);
    }
  };

  useEffect(() => {}, [showLinkOptions, showAthleticsOptions]);
  return (
    <>
      {isMobile ? (
        <>
          <MobileMenuIconContainer
            id="menu-mobile"
            onClick={() => showNavMenu()}
          >
            <MenuIcon className="fas fa-bars" />
          </MobileMenuIconContainer>
          <MenuMobileContainer id="menu-mobile-container">
            <div className="NavigationBlackMobile">
              <Link className="LinkMobile Link-Option-Mobile" to="/">
                HOME
              </Link>
              {/* <Link className="LinkMobile Link-Option-Mobile" to="/donate">
                SPONSOR
              </Link> */}
              <Link
                className="LinkMobile Link-Option-Mobile"
                to="/boys-athletics"
              >
                ATHLETICS
              </Link>
              {/* <div className="Link-Container">
                <Link className="LinkMobile Link-Option-Mobile" to="/volunteer">
                  VOLUNTEER
                </Link>
                <Link className="Link-Option-Mobile" to="/coach">
                  COACH
                </Link>
                <Link className="Link-Option-Mobile" to="/referee">
                  REFEREE
                </Link>
                <Link className="Link-Option-Mobile" to="/gym-monitor">
                  GYM MONITOR
                </Link>
              </div> */}
            </div>
          </MenuMobileContainer>
          {children}
        </>
      ) : (
        <>
          <div className="NavigationBlack">
            <Link className="Link" to="/">
              HOME
            </Link>
            {/* <Link className="Link" to="/donate">
              SPONSOR
            </Link> */}
            {!showAthleticsLinkOptions && (
              <Link
                onMouseEnter={() => showAthleticsOptions(true)}
                className="Link"
                to="/boys-athletics"
              >
                ATHLETICS
              </Link>
            )}
            {showAthleticsLinkOptions && (
              <div
                onMouseLeave={() => showAthleticsOptions(false)}
                className="Link-Container"
              >
                <Link
                  onMouseEnter={() => showAthleticsOptions(true)}
                  className="Link"
                  to="/boys-athletics"
                >
                  ATHLETICS
                </Link>
                <Link className="Link" to="/boys-athletics">
                  BOYS
                </Link>
                <Link className="Link-Option" to="/girls-athletics">
                  GIRLS
                </Link>
              </div>
            )}
            {/* {!showLinkOptions && (
              <Link
                onMouseEnter={() => showOptions(true)}
                className="Link"
                to="/volunteer"
              >
                VOLUNTEER
              </Link>
            )}
            {showLinkOptions && (
              <div
                onMouseLeave={() => showOptions(false)}
                className="Link-Container"
              >
                <Link className="Link" to="/volunteer">
                  VOLUNTEER
                </Link>
                <Link className="Link-Option" to="/coach">
                  COACH
                </Link>
                <Link className="Link-Option" to="/referee">
                  REFEREE
                </Link>
                <Link className="Link-Option" to="/gym-monitor">
                  GYM MONITOR
                </Link>
              </div>
            )} */}
          </div>
          {children}
        </>
      )}
    </>
  );
};

export default Navigation;
