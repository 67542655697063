// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bread {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Kanit", sans-serif;
  height: 4rem;
  color: orange;
  font-size: 1.5em;
  gap: 1em;
  z-index: 1;
}
.bread_crumb-link:visited {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/BreadCrumb/BreadCrumb.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,QAAA;EACA,UAAA;AACF;AAEI;EACE,UAAA;AAAN","sourcesContent":[".bread {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  font-family: \"Kanit\", sans-serif;\n  height: 4rem;\n  color: orange;\n  font-size: 1.5em;\n  gap: 1em;\n  z-index: 1;\n\n  &_crumb-link {\n    &:visited {\n      color: red;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
