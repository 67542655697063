// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  position: relative;
  width: 100%;
  height: 500px;
}

.carousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel img {
  width: 100%;
  height: 110%;
  position: sticky;
}

.carousel::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 100%;
  transform: translateZ(8px);
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.013) 8%, rgba(0, 0, 0, 0.049) 14.8%, rgba(0, 0, 0, 0.104) 20.8%, rgba(0, 0, 0, 0.175) 26%, rgba(0, 0, 0, 0.259) 30.8%, rgba(0, 0, 0, 0.352) 35.3%, rgba(0, 0, 0, 0.45) 39.8%, rgba(0, 0, 0, 0.55) 44.5%, rgba(0, 0, 0, 0.648) 49.5%, rgba(0, 0, 0, 0.741) 55.2%, rgba(0, 0, 0, 0.825) 61.7%, rgba(0, 0, 0, 0.896) 69.2%, rgba(0, 0, 0, 0.951) 77.9%, rgba(0, 0, 0, 0.987) 88.1%, black 100%);
  z-index: 3;
}`, "",{"version":3,"sources":["webpack://./src/components/ContentBackground/ContentBackground.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,0BAAA;EACA,oBAAA;EACA,8cAAA;EAmBA,UAAA;AAjBF","sourcesContent":[".carousel-container {\n  position: relative;\n  width: 100%;\n  height: 500px;\n}\n\n.carousel {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.carousel img {\n  width: 100%;\n  height: 110%;\n  position: sticky;\n}\n\n.carousel::after {\n  content: \"\";\n  position: absolute;\n  top: 50%;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  transform-origin: 0 100%;\n  transform: translateZ(8px);\n  pointer-events: none;\n  background-image: linear-gradient(\n    to bottom,\n    rgba(0, 0, 0, 0) 0%,\n    rgba(0, 0, 0, 0.013) 8%,\n    rgba(0, 0, 0, 0.049) 14.8%,\n    rgba(0, 0, 0, 0.104) 20.8%,\n    rgba(0, 0, 0, 0.175) 26%,\n    rgba(0, 0, 0, 0.259) 30.8%,\n    rgba(0, 0, 0, 0.352) 35.3%,\n    rgba(0, 0, 0, 0.45) 39.8%,\n    rgba(0, 0, 0, 0.55) 44.5%,\n    rgba(0, 0, 0, 0.648) 49.5%,\n    rgba(0, 0, 0, 0.741) 55.2%,\n    rgba(0, 0, 0, 0.825) 61.7%,\n    rgba(0, 0, 0, 0.896) 69.2%,\n    rgba(0, 0, 0, 0.951) 77.9%,\n    rgba(0, 0, 0, 0.987) 88.1%,\n    black 100%\n  );\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
