import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WelcomeContainer from "./pages/Welcome";
import BoysContainer from "./pages/Boys";
import GirlsContainer from "./pages/Girls";

const router = createBrowserRouter([
  {
    path: "/",
    element: <WelcomeContainer />,
  },
  {
    path: "/boys-athletics",
    element: <BoysContainer />,
  },
  {
    path: "/girls-athletics",
    element: <GirlsContainer />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
