import ContentContainer from "../../components/Content";
import ContentBackground from "../../components/ContentBackground/ContentBackground";
import styled from "styled-components";
import Footer from "../../components/Footer/Footer";
import Navigation from '../../components/Navigation/Navigation'

export const BoysContainer = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  perspective: 10px;
`;

const Boys = ({ pageData: { boys } }) => {
  return (
    <>
      {boys && (
        <>
          <Navigation />
          <BoysContainer>
            <ContentBackground content={boys}></ContentBackground>
            <ContentContainer content={boys}></ContentContainer>
          </BoysContainer>
          <Footer />
        </>
      )}
    </>
  );
};

export default Boys;
