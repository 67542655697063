import React from "react";
import styled from "styled-components";
import "./Footer.scss";

const StyledContainer = styled.div`
  height: 20vh;
  background-color: #111;
  color: #fa8320;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Georama", sans-serif;
`;

const Footer = () => {
  return (
    <StyledContainer>
      <div className="company-copyright-container">
        ICE Athletics &#174; 2023
      </div>
      <a href="tel:7345358974" className="phone-container">
        Contact: (734)535-8974
      </a>
      <a href="mailto:admin@ice-athletics.com" className="phone-container">
        Email: Admin@ice-athletics.com
      </a>
      <div className="built-by-container">Powered by CMJ Software</div>
      <div className="built-by-container">Logo designed by CB Graphicx</div>
    </StyledContainer>
  );
};

export default Footer;
