import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appID } from "../../shared/app-service";
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
  handleRoute,
} from "@cmj526/made-for-us";

export const useBoysHook = () => {
  const [pageData, setPageData] = useState({});
  const history = useNavigate();

  useEffect(() => {
    hasCookieAndStorage('boys') ? 
    setPageData(decode(appStore.get(`${appID}boys`))) : 
    setSessionData(appID,"boys", "/api/boys", setPageData);
  }, []);

  return {
    pageData,
    history,
  };
};
